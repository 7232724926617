// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-hr-js": () => import("./../../../src/pages/about.hr.js" /* webpackChunkName: "component---src-pages-about-hr-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-hr-js": () => import("./../../../src/pages/contact.hr.js" /* webpackChunkName: "component---src-pages-contact-hr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-hr-js": () => import("./../../../src/pages/index.hr.js" /* webpackChunkName: "component---src-pages-index-hr-js" */)
}

